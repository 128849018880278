import i18n from 'i18next';
import enUsTrans from './locales/en-us.json';
import zhTwTrans from './locales/zh-tw.json';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) //init i18next
  .init({
    lng: navigator.language || 'en',
    //引入资源文件
    resources: {
      en: {
        translation: enUsTrans,
      },
      zh: {
        translation: zhTwTrans,
      },
    },
    //选择默认语言，选择内容为上述配置中的key，即en/zh
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
