import Menu from './components/Menu';
import React, { useEffect, lazy, Suspense } from 'react';
import {
  IonApp,
  IonContent,
  IonRouterOutlet,
  IonSplitPane,
  useIonToast,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
// import UserProvider from "./providers/UserProvider"; // this is what integrates firebase functionalities
import ReactGA from 'react-ga';
import { GA_TRACKING_ID } from './constants/index';
// import { observer } from "mobx-react-lite";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import 'react-phone-input-2/lib/style.css';

import axios from 'axios';
import { useStore } from './mobx/service';
import { useHistory } from 'react-router-dom';
import { API_ROOT_URL } from './constants/index';
import './i18n';
import { useTranslation } from 'react-i18next';

/* Theme variables */
import './theme/variables.css';

const MsgLoginPage = lazy(() => import('./pages/msgLoginPage/index'))
const HomePage = lazy(() => import('./pages/Subscribe/HomePage'))
const PostPage = lazy(() => import('./pages/Post/Post'));
const SignUp = lazy(() => import('./pages/SignUp'));
const SubscribeLandingPage = lazy(() => import('./pages/SubscribeLanding'));
const Support = lazy(() => import('./pages/Subscribe/Components/Support'));
const Result = lazy(() => import('./pages/Subscribe/Components/Result'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const ConfirmPhonePage = lazy(
  () => import('./pages/ConfirmPhonePage/ConfirmPhonePage')
);
const ConfirmRegistrationPage = lazy(
  () => import('./pages/ConfirmRegistrationPage/ConfirmRegistrationPage')
);
const ResetPasswordLandingPage = lazy(
  () => import('./pages/ResetPasswordStartPage')
);
const ResetPasswordPage = lazy(
  () => import('./pages/ResetPasswordPage/ResetPasswordPage')
);
const StripeTest = lazy(() => import('./pages/Subscribe'));
const Landing = lazy(() => import('./pages/LandingHome'));

const axiosApiInstance = axios.create();

export { axiosApiInstance };

const App: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  const [t] = useTranslation();
  localStorage.setItem('home_url', '');

  setupIonicReact({
    mode: "ios",
  });

  const [present] = useIonToast();

  //Google Analytics initialization & integration
  ReactGA.initialize(GA_TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // Response interceptor for API calls
  axiosApiInstance.interceptors.response.use(
    (response) => {
      const { data = {} } = response;
      const { code = 0, msg = '' } = data;
      if (code !== 0) {
        present({
          color: 'danger',
          message: msg,
          duration: 3000,
        });
        return Promise.reject({
          code,
          data,
          msg,
        });
      }
      return data;
    },
    async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        const apiUrl = `${API_ROOT_URL}/refresh`;
        let config = {
          headers: {
            Authorization: 'Bearer ' + store.appStates.refreshToken,
          },
        };
        originalRequest._retry = true;
        return axios
          .post<{ access_token: string }>(apiUrl, {}, config)
          .then((result) => {
            if (result.status === 200) {
              store.appStates.sessionToken = result.data.access_token;
              axios.defaults.headers.common['Authorization'] =
                'Bearer ' + result.data.access_token;
              return axiosApiInstance(originalRequest);
            } else {
              return axiosApiInstance(originalRequest);
              // history.push('/login');
            }
          });
        // return axiosApiInstance(originalRequest);
      } else if (error.response.status === 401 && originalRequest._retry) {
        history.push('/login');
      }
      return Promise.reject(error);
    }
  );

  return (
    // <UserProvider>
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId='main'>
          <Menu />
          <IonRouterOutlet id='main'>
            <Suspense fallback={<div>{t('Loading')}</div>}>
              <Route path='/signup' component={SignUp} exact />
              <Route path='/login' component={LoginPage} exact />
              <Route
                path='/register/:token'
                component={ConfirmRegistrationPage}
                exact={true}
              />
              <Route
                path='/confirm/subscribe'
                component={ConfirmPhonePage}
                exact={true}
              />
              <Route
                path='/resetpassword'
                component={ResetPasswordLandingPage}
                exact={true}
              />
              <Route
                path='/reset/:token'
                component={ResetPasswordPage}
                exact={true}
              />
              <Route
                path='/confirm/login'
                component={MsgLoginPage}
                exact={true}
              />
              <Route
                path='/subscribe_landing'
                component={SubscribeLandingPage}
                exact={true}
              />
              <Route path='/stripe_test' component={StripeTest} exact />
              {/* <Route path="/creator/:user_name" component={Subscribe} exact /> */}
              <Route path="/creator/:user_name" component={HomePage} exact />
              <Route path='/support' component={Support} exact />
              <Route path='/result/:amount' component={Result} exact />
              <Route path='/post/:id' component={PostPage} exact={true} />
              <Route path='/landing' component={Landing} exact={true} />
              {/*<Redirect from="/" to="/landing" exact />*/}
            </Suspense>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
    // </UserProvider>
  );
};

export default App;
