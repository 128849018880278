import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { AsyncTrunk } from 'mobx-sync';
import AppStore ,{ StoreProvider } from './mobx/service';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { STRIPE_KEY } from './constants';

//initiating mobx store
const appStore = new AppStore();

//initiating persisted instance of state store
const trunk = new AsyncTrunk(appStore, { storage: localStorage });
trunk.init();

// for testing only, need to react keys to .env file later
const stripePromise = loadStripe(STRIPE_KEY as string);

// ReactDOM.render(<App />, document.getElementById('root'));

ReactDOM.render(
    <StoreProvider value={appStore}>
        <Elements stripe={stripePromise}>
            <App />
        </Elements>
    </StoreProvider>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
