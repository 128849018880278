// import { observable, computed, action } from "mobx";
import { makeObservable, observable, action,makeAutoObservable, computed } from "mobx";
import React from 'react'

interface AppStates{
    isAuthenticated:boolean;
    // showLoginModal?:boolean;
    // showPostUploadModal?: boolean;
    isSignedUp: boolean;
    isSubscribed: boolean;
    sessionToken?: string;
    refreshToken?: string;
    creatorID?: number;
    userID?: number;
    userHandle?: string;
    stripeCustomerId?: string;
    creatorHandle?: string;
    creatorName?: string;
    userName?:string;
    user_avatar_url?: string;
    // phoneNumberBuffer?: string;
    creator_avatar_url?: string;
    creator_bio?: string;
    userPhoneNumber?: string;
    tierObjs?: tierObject[];
}

interface tierObject{
    create_date: string;
    creator_id: number;
    tier_id: number;
    tier_name: string;
    price_id: string|null;
    product_id: string|null;
    tier_perks: string[];
    tier_price: number;
}

let defaultValue:AppStates={
    isAuthenticated:false,
    isSignedUp: false,
    isSubscribed: false
}

export default class AppStore {
    @observable appStates:AppStates = defaultValue;

    constructor(){
        makeAutoObservable(this)
    }

}

export const StoreContext = React.createContext<AppStore>(new AppStore());
export const StoreProvider = StoreContext.Provider;
export const useStore = () => React.useContext(StoreContext);