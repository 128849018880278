import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonButton,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';

import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { mailOutline, mailSharp } from 'ionicons/icons';
import './Menu.css';

import { useStore } from '../mobx/service';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Logout',
    url: '/logout',
    iosIcon: mailOutline,
    mdIcon: mailSharp
  }
];

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => ++value); // update the state to force render
}

const Menu: React.FC = () => {
  const [t] = useTranslation();

  const location = useLocation();
  const store = useStore();
  const history = useHistory();

  const forceUpdate = useForceUpdate();

  function goToLogin(){
    history.push({
      pathname:'/login',
      state:{goBack:true}
    })
  }

  function logOut(){
    store.appStates.isAuthenticated = false;
    store.appStates.sessionToken = '';
    forceUpdate();
  }

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        {
          store.appStates.isAuthenticated?
          <>
            <IonList id="inbox-list">
              <IonListHeader>{store.appStates.userName}</IonListHeader>
              <IonNote>@{store.appStates.userHandle}</IonNote>
              {/* {
                appPages.map((appPage, index) => {
                  return (
                    <IonMenuToggle key={index} autoHide={true}>
                      <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                        <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                        <IonLabel>{appPage.title}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  );
                })
              } */}
            </IonList>
            <IonButton className="menu-button" onClick={()=>logOut()} expand='block'>{t('Log Out')}</IonButton>
          </>
          :
          <IonMenuToggle autoHide={true}>
            <IonButton className="menu-buttom" onClick={()=>goToLogin()} expand='block'>{t('Login')}</IonButton>
          </IonMenuToggle>
          
        }

      </IonContent>
    </IonMenu>
  );
};

export default Menu;
